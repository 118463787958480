<template lang="pug">
#CustomFields
  v-row.setting-title.align-center.py-3.px-4(no-gutters)
    v-col(cols="12", md="9")
      h3.d-flex.align-center.mb-0
        .cube-icon.small.bg-primary.mr-3
          v-icon(size="19", color="white") icon-folder
        span {{$t('SETTING.KNOWLEDGEARTICLESUBJECTSETTING')}}
    v-col.text-md-right.w-100(cols="12", md="3")
      v-btn(@click="showAddSubject" height="40" color="green" depressed dark)
        v-icon.mr-1(size="14") icon-add
        span {{$t('KNOWLEDGEARTICLESUBJECTSETTING.ADD_SUBJECT')}}
  .px-4.py-3 
    section
      v-data-table.dashboard-table(
        :headers="subjectHeader",
        :items="subjectList",
        :items-per-page="itemPerPage",
        :page.sync="page",
        @page-count="pageCount = $event",
        multi-sort
        fixed-header=true,
        hide-default-footer="hide-default-footer",
        dense
      )
        template( v-slot:item.name="{ item, index }" )
            v-text-field.v-text-field__slot(v-if='onEdit && index == editIndex' ref="form" v-model="item.name" hide-details="true"  dense single-line :autofocus="true" :rules="formRule.fieldRules")
            .ellipsis(v-else :title='item.name')
                span {{item.name}}
        template( v-slot:item.description="{ item, index }")
            v-text-field.v-text-field__slot(v-if='onEdit && index == editIndex' v-model="item.description" :hide-details="true" dense single-line :autofocus="true")
            .ellipsis(v-else :title='item.description')
                span {{item.description}}
        template(v-slot:item.active="{ item, index }")
          v-btn(v-if='onEdit && index == editIndex' icon @click="close(index)")
            v-icon(size="18" color="red") mdi-window-close
          v-btn(v-if='!onEdit' icon @click="editItem(item, index)")
            v-icon(size="18" ) icon-edit
          v-btn(v-if='onEdit && index == editIndex' icon @click="save(item)")
            v-icon(size="18" color="green") fas fa-check
          v-btn(v-if='!onEdit || index != editIndex' icon @click="onDelete(item, index)")
            v-icon(size="18") icon-remove
      v-pagination.py-1.mb-3(v-model='page' :length='pageCount' v-if='pageCount > 1' circle :total-visible="10") 
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
            success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
    v-dialog(v-model="addSubjectDialog" max-width="500" content-class="setting-dialog" v-if="addSubjectDialog")
      v-card
        v-card-title.d-flex.justify-center {{$t('KNOWLEDGEARTICLESUBJECTSETTING.ADD')}}
        v-card-text
          v-form(v-model="addSubjectData.valid", ref="form")
            v-text-field.no-border.v-text-field__slot(
              v-model="addSubjectData.name",
              type="text",
              :label=`$t('KNOWLEDGEARTICLESUBJECTSETTING.NAME')`,
              prepend-icon="mdi-mail",
              :rules="formRule.fieldRules"
            )
            v-textarea.textarea-style.no-border.v-text-field__slot(
              v-model="addSubjectData.description",
              type="text",
              :label=`$t('KNOWLEDGEARTICLESUBJECTSETTING.DESCRIPTION')`,
              prepend-icon="mdi-equal-box",   
              no-resize           
            )
          v-card-actions.mt-8.px-0.py-0
            v-row(no-gutters)
              v-col.pr-2(cols="6")
                v-btn(
                  :ripple="false",
                  @click="onCancel",
                  width="100%",
                  height="40",
                  color="cancel",
                  depressed,
                  dark
                ) {{$t('GENERAL.CANCEL')}}
              v-col.pl-2(cols="6")
                v-btn(
                  :ripple="false",
                  @click="onAddSubject",
                  width="100%",
                  height="40",
                  color="success",
                  depressed,
                  dark
                ) {{$t('GENERAL.ADD')}}
</template>

<script>
import Vue from "vue";
import KnowledgeArticleDataService from "@/services/KnowledgeArticleDataService";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import i18n from '/common/plugins/vue-i18n.js' 

// import Sortable from "sortablejs";

export default Vue.extend({
  components: {
    messageDialog,
    successDialog,
    errorDialog,
  },
  data() {
    return {
      //- dialog
      messageDialog: false,
      message: '',
      successDialog: false,
      errorDialog: false,
      errorMessage: '',
      searchMangeUser: "",

      addSubjectDialog: false,
      addSubjectData: {
        valid: true,
        name: "",
        description: "",
      },
      //- table
      subjectHeader: [      
        {
          text: i18n.t('KNOWLEDGEARTICLESUBJECTSETTING.NAME'),
          value: "name",
          align: "left",
          width: '30%',
          class: "blue lighten-5",
          sortable: false
        },
        {
          text: i18n.t('KNOWLEDGEARTICLESUBJECTSETTING.DESCRIPTION'), 
          value: "description",
          align: "left",
          width: '50%',
          class: "blue lighten-5",
          sortable: false
        },
        {
          text: i18n.t('KNOWLEDGEARTICLESUBJECTSETTING.ACTIVE'),
          value: "active",
          align: "center",
          width: '15%',
          class: "blue lighten-5",
          sortable: false
        },
      ],
      page: 1,
      pageCount: 1,
      itemPerPage: 12,
      //- data
      subjectList:[],
      onEdit: false,
      editIndex: -1,
      delIndex: -1,
      delId: null,
      originalItem: {},
      mode: '',
      //- form rule
      formRule: {
        fieldRules: [(v) => !!v || i18n.t('RULE.RULE_R')],
      },
    
    };
  },
  created() {
    KnowledgeArticleDataService.subjectlist()
    .then((response)=>{
      this.subjectList = response.data;
    });
  },
  methods: {
    validateForm() {
      if (this.$refs.form.validate()) {
        return true;
      } else {
        return false;
      }
    },
    showMessageDialog(message) {
        this.messageDialog = true;
        this.message = message;
    },
    onEmitMessageDialog(val) {
      this.messageDialog = false;
      if (val) {
        if (this.mode == 'DELETE') {
          KnowledgeArticleDataService.deletesubject(this.delId)
          .then(() => {
            this.subjectList.splice(this.delIndex, 1);
          })
          .finally(() => {
            this.delId = null;
            this.delIndex = -1;
          });
        }
      }
    },
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showErrorDialog(message) {  
      this.errorDialog = true;      
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
    // 打開編輯
    editItem (item, index) {
      this.originalItem = JSON.parse(JSON.stringify(item));
      this.editIndex = index;
      this.onEdit = true;
    },
    // 儲存編輯
    save(item) {
      if (this.validateForm()) {
        KnowledgeArticleDataService.editsubject(item);
        this.onEdit = false;
      }
    },
    // 關閉編輯
    close(index) {
      this.$set(this.subjectList, index, this.originalItem);
      this.onEdit = false;
    },
    // 刪除確認
    onDelete(item, index) {
      this.showMessageDialog(i18n.t('KNOWLEDGEARTICLESUBJECTSETTING.MESSAGE'));
      this.delIndex = index;
      this.delId = item.id;
      this.mode = 'DELETE';
    },
    //- add subject
    showAddSubject() {
      this.addSubjectDialog = true;
      this.addSubjectData = {
        valid: true,
        name: "",
        description: "",
      }
    },
    onAddSubject() {
      KnowledgeArticleDataService.createsubject(this.addSubjectData)
      .then(response => {
        this.subjectList.unshift(response.data);
      });
      this.addSubjectDialog = false;
    },
    onCancel() {
      this.addSubjectDialog = false;
    },
  },
  // directives: {
  //   sortableDataTable: {
  //     bind(el, binding, vnode) {
  //       const options = {
  //         animation: 150,
  //         onUpdate: function (event) {
  //           vnode.child.$emit("sorted", event);
  //         },
  //       };
  //       Sortable.create(el.getElementsByTagName("tbody")[0], options);
  //     },
  //   },
  // },
});
</script>
<style lang="css">
.nopadding {
  padding-left: 0;
}
.dataTableColor:hover {
  background-color: rgba(180, 240, 255, 0.164) !important;
}
</style>