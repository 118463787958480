<template lang="pug">
#CustomFields
    v-row.setting-title.align-center.py-3.px-4(no-gutters)
        v-col(cols="12", md="4" ).mr-auto
            h3.d-flex.align-center.mb-0
                .cube-icon.small.bg-primary.mr-3
                    v-icon(size="19", color="white") mdi-view-list-outline
                span {{$t('SETTING.FILTERSETTING')}}
        v-col(cols='6' v-if='user.is_staff').text-right
            v-btn-toggle(v-model="toggle" tile color='primary' group mandatory)
                v-btn(height='40' @click='togglePage(true)' ) {{ $t('FILTERSETTING.PERSONAL_SETTING') }}
                v-btn(height='40' @click='togglePage(false)' ) {{ $t('FILTERSETTING.SYSTEM_SETTING') }}
    section(v-if='personalSetting && !loading' class='px-4 py-3')
      v-row.align-center(no-gutters)
        v-col.d-flex(cols="8")
            label.mr-4.w-50 {{$t('TABLESETTING.PAGE')}}
                v-select(v-model='tab' :items='tab_items' @change='arrangedata(true)' height='34' flat dense solo background-color='#F0F0F0')
                    template(v-slot:selection='{ item }')
                        .t-black {{ getItemNameFromList(classTypeList, item) }}
                    template(v-slot:item='{ item }')
                        .t-black {{ getItemNameFromList(classTypeList, item) }}
            label.w-50(v-if='tab == "Case" || tab == "Opportunity"') {{$t('TABLESETTING.PROCESS')}}
                v-select( v-model='slcStgType' :items='stageTypes' @change='arrangedata(false)' item-text='stage_type_name' item-value='id' return-object  height='34' flat dense solo background-color='#F0F0F0')
                    template(v-slot:prepend-inner)
                        v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
        v-col.d-flex.justify-end(cols="4")
            v-btn.mt-6( @click="showAddFilterDialog" height="40", color="green",  depressed, dark)
                v-icon.mr-1(size="14") icon-add
                span {{$t('FILTERSETTING.ADD')}}
      v-data-table.custom-view-table( item-key="editId" 
        :headers="header" 
        :header-props="{sortIcon: 'mdi mdi-arrow-up'}" 
        :items="customFilters",
        :items-per-page="itemPerPage",
        :page.sync="page",
        @page-count="pageCount = $event",
        multi-sort
        height="610",
        fixed-header,
        hide-default-footer="hide-default-footer",
        :loading = 'loading'
      )
        template( v-slot:item.name="{ item }")
            .ellipsis( :title='item.name')
                span {{item.name}}
        template(v-slot:item.default="{ item }")
            v-checkbox.v-input__slot_checkbox.v-input__slot.v-messages(v-model="item.default" hide-details="auto" style='height: 35px;' @click='setDefault(item)') 
        template(v-slot:item.owner_id="{ item }")
            .d-flex.w-100
              v-list-item-avatar.ma-0.mx-2.d-inline-block(color='aliceBlue' size='40' v-if="item.owner != null")
                v-icon(v-if="item.owner == null") mdi-account
                v-icon(v-else-if="item.owner.usersetting.photo == null") mdi-account
                img(v-else :src='item.owner.usersetting.photo')
              v-btn.w-75.d-inline-block(v-if="item.owner != null" :href='onMail(item.owner.email)' :title='item.owner.last_name+" "+item.owner.first_name' color='primary' text) 
                .ellipsis.pt-2.w-100  
                  span.w-100 {{ item.owner.last_name+' '+item.owner.first_name }}
        template(v-slot:item.active="{ item }" )
            v-btn(icon @click="showEditFilterDialog(item)" :disabled='!canEdit_filter(item)')
                v-icon(size="18" ) icon-edit
            v-btn(icon @click="showCopyFilterDialog(item)")
                v-icon(size="18") mdi-content-copy
            v-btn(icon @click="deleteTable(item)" :disabled='!canDelete_filter(item)')
                v-icon(size="18") icon-remove
      v-pagination.mt-3(v-model='page' :length='pageCount' circle :total-visible="10" v-if='!loading')

    section(v-if='systemSetting && !loading' class='px-4 py-3')
        v-row.align-center(no-gutters)
            v-col.d-flex(cols="8")
                label.mr-4.w-50 {{$t('TABLESETTING.PAGE')}}
                    v-select(v-model='tab' :items='tab_items' @change='arrangedata(true)' height='34' flat dense solo background-color='#F0F0F0')
                        template(v-slot:selection='{ item }')
                            .t-black {{ getItemNameFromList(classTypeList, item) }}
                        template(v-slot:item='{ item }')
                            .t-black {{ getItemNameFromList(classTypeList, item) }}
                label.w-50(v-if='tab == "Case" || tab == "Opportunity"') {{$t('TABLESETTING.PROCESS')}}
                    v-select( v-model='slcStgType' :items='stageTypes' @change='arrangedata(false)' item-text='stage_type_name' item-value='id' return-object  height='34' flat dense solo background-color='#F0F0F0')
                        template(v-slot:prepend-inner)
                            v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
            v-col.d-flex.justify-end(cols="4")
                v-btn.mt-6( @click="showAddFilterDialog" height="40", color="green",  depressed, dark)
                    v-icon.mr-1(size="14") icon-add
                    span {{$t('FILTERSETTING.ADD')}}
        v-data-table.custom-view-table( item-key="editId" 
            :headers="header_system" 
            :header-props="{sortIcon: 'mdi mdi-arrow-up'}" 
            :items="customFilters",
            :items-per-page="itemPerPage",
            :page.sync="page",
            @page-count="pageCount = $event",
            multi-sort
            height="610",
            fixed-header,
            hide-default-footer="hide-default-footer",
            :loading = 'loading'
        )
            template( v-slot:item.name="{ item }")
                .ellipsis( :title='item.name')
                    span {{item.name}}
            template(v-slot:item.default="{ item }")
                v-checkbox.v-input__slot_checkbox.v-input__slot.v-messages(v-model="item.default" hide-details="auto" @click='setSystemDefault(item)') 
            template(v-slot:item.owner_id="{ item }")
                .d-flex.w-100
                    v-list-item-avatar.ma-0.mx-2.d-inline-block(color='aliceBlue' size='40' v-if="item.owner != null")
                        v-icon(v-if="item.owner == null") mdi-account
                        v-icon(v-else-if="item.owner.usersetting.photo == null") mdi-account
                        img(v-else :src='item.owner.usersetting.photo')
                    v-btn.w-75.d-inline-block(v-if="item.owner != null" :href='onMail(item.owner.email)' :title='item.owner.last_name+" "+item.owner.first_name' color='primary' text) 
                        .ellipsis.pt-2.w-100  
                            span.w-100 {{ item.owner.last_name+' '+item.owner.first_name }}
        v-pagination.mt-3(v-model='page' :length='pageCount' circle :total-visible="10" v-if='!loading')
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
        message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
        success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
    v-dialog(v-model='addFilterDialog' width='800' content-class='statusDialog' v-if='addFilterDialog' scrollable)
        add-filter-dialog(@emitAddFilterDialog='onEmitAddFilter' :class-name='tab' :stage-type='slcStgType' :stage-type-list='stageTypes' :customField='CFList')
    v-dialog(v-model='editFilterDialog' width='800' content-class='statusDialog' v-if='editFilterDialog' scrollable persistent) 
        edit-filter-dialog(@emitEditFilterDialog='onEmitEditFilter' @emitCopyFilterDialog='onEmitCopyFilter' :custom-filter-data ='editData' :editMode='editMode' :class-name='tab' :stage-type='slcStgType' :stage-type-list='stageTypes' :customField='CFList')

</template>

<script>
import Vue from "vue";
import addFilterDialog from "@/components/Dialog/Setting/addFilterDialog";
import editFilterDialog from "@/components/Dialog/Setting/editFilterDialog";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import draggable from 'vuedraggable';

import classTypeList from "@/array/the_classTypeList";
import CustomFieldDataService from "@/services/CustomFieldDataService";
import CustomFilterDataService from "@/services/CustomFilterDataService";
import PersonalSettingService from "@/services/PersonalSettingService";
import StageDataService from "@/services/StageDataService";
import i18n from '/common/plugins/vue-i18n.js' 

export default Vue.extend({
  components: {
    draggable,
    messageDialog,
    successDialog,
    errorDialog,
    addFilterDialog,
    editFilterDialog,
  },
  data() {
    return {
        // tab
        tab: 'Contact',
        tab_items:['Contact', 'Opportunity', 'Organization','Case'],
        // dialog
        messageDialog: false,
        message: '',
        successDialog: false,
        errorDialog: false,
        errorMessage: '',
        addFilterDialog: false,    
        editFilterDialog: false,
        editData: [],
        caseStageType: null,
        dealStageType: null,
        stageTypes: [],
        slcStgType: null,
        editMode: '',
        // table  
        loading: true,
        header: [
            { text: i18n.t('TABLESETTING.DEFAULT'), value: "default", align: "center" , width: '10%' ,class: "blue lighten-5", sortable: false,},
            { text: i18n.t('TABLESETTING.NAME'), value: "name", align: "left" , width: '40%' ,class: "blue lighten-5" },
            { text: i18n.t('CONTACTSFIELDLIST.owner'), value: "owner_id", align: "center" , width: '25%' ,class: "blue lighten-5" },
            { text: i18n.t('GENERAL.ACTIVE') , value: "active", align: "center", width: '20%' ,class: "blue lighten-5", sortable: false},
        ],
        header_system: [
            { text: i18n.t('TABLESETTING.DEFAULT'), value: "default", align: "center", width: '10%',class: "blue lighten-5", sortable: false,},
            { text: i18n.t('TABLESETTING.NAME'), value: "name", align: "left",width: '60%',class: "blue lighten-5" },
            { text: i18n.t('CONTACTSFIELDLIST.owner'), value: "owner_id",  align: "center" ,class: "blue lighten-5" },
        ],
        tables: [],
        allCustomField: [],
        allCustomFilter: [],
        customFilters: [],
        page: 1,
        pageCount: 1,
        itemPerPage: 11,
        deleteData: null,
        mode: null,
        CFList: null,
        filterSetting: true,
        personalSetting: true,
        systemSetting: false,
        user: JSON.parse(window.localStorage.getItem('user')),
        userdata: null,
        toggle: 0,
        isSystem:false,
        // list
        classTypeList: classTypeList,
    };
  },
    created() {
        this.loading = true;
        // get all CustomFilter
        CustomFilterDataService.list()
        .then(response => {
            this.allCustomFilter = response.data;
            this.arrangedata();
        })
        .catch(response => {
            this.showErrorDialog(response);
        });

        // get stage type
        let arr = ['case', 'deal'];
        arr.forEach(e => {
            StageDataService.stageList(e)
            .then(response => {
                let stage = {stage_type_name: i18n.t('GENERAL.ALL'), id: 'all'};
                if(e == 'case'){
                    this.caseStageType = response.data;
                    this.caseStageType.unshift(stage);
                }
                else if(e == 'deal'){
                    this.dealStageType = response.data;
                    this.dealStageType.unshift(stage);
                }
            })
            .catch((response)=>{
                this.showErrorDialog(response)
            })
        })

        // get custom field
        CustomFieldDataService.getCustomFieldDetail("Setting")
        .then((response) => {
            this.allCustomField = response.data;
        })
        .catch((response) => {
            this.showErrorDialog(response);
        });

        // get userData
        PersonalSettingService.getCurrentUserDetail()
        .then(response => {
            this.userdata = response.data[0];
        })
        .catch(response => {
            this.showErrorDialog(response);
        })
    },
    updated(){
        this.loading = false;
    },
    methods: {
        onMail: function (mail) {
            return "mailto:" + mail;
        },
        canEdit_filter(item){
            let is_seed_data = item.owner == null ? true : false;
            let is_created_by_this_user = item.owner != null ? item.owner.id == this.user.userid : false;
            let this_user_is_admin = this.user.is_staff;

            if(is_seed_data){
                if(this_user_is_admin){
                    return true;
                }
                else{
                    return false;
                }
            }
            else{
                if(this_user_is_admin){
                    return true;
                }else{
                    if(is_created_by_this_user){
                        return true;
                    }else{
                        return false;
                    }
                }
            }
        },
        canDelete_filter(item){
            let is_seed_data = item.owner == null ? true : false;
            let is_created_by_this_user = item.owner != null ? item.owner.id == this.user.userid : false;
            let this_user_is_admin = this.user.is_staff;

            if(is_seed_data){
                return false;
            }
            else{
                if(this_user_is_admin){
                    return true;
                }
                else{
                    if(is_created_by_this_user){
                        return true;
                    }else{
                        return false;
                    }
                }
            }
        },
        togglePage(val) {
            this.loading = true;
            this.page = 1;
            if(!val){
                this.isSystem = true;
            }else{
                this.isSystem = false;
            }
            CustomFilterDataService.list()
            .then(response => {
                this.allCustomFilter = response.data;
                if(val){
                    // hide and show UI
                    this.personalSetting = true;
                    this.systemSetting = false;
                }
                else{
                    // hide and show UI
                    this.personalSetting = false;
                    this.systemSetting = true;
                }
            })
            .catch(response => {
                this.showErrorDialog(response);
            })
            .finally(()=>{
                this.arrangedata(false);
            })
        },
        arrangedata(isChangeTab){
            let promise = new Promise((resolve) => {
                this.loading = true;
                if(this.tab == 'Case' || this.tab == 'Opportunity'){
                    this.tab == 'Case' ? this.stageTypes = this.caseStageType : this.stageTypes = this.dealStageType;
                    if(isChangeTab){
                        this.slcStgType = this.stageTypes[0];
                    }
                    this.customFilters = this.allCustomFilter.filter(e => e.class_name == this.tab && e.stage_type_id == this.slcStgType.id);
                    resolve();
                }
                else{
                    this.slcStgType = null;
                    this.customFilters = this.allCustomFilter.filter(e => e.class_name == this.tab);
                    resolve();
                }
            })
            
            promise
            .then(()=>{
                if(this.systemSetting){
                    this.customFilters = this.customFilters.filter(e => e.public == true);
                }
            })
            .finally(()=>{
                let page_defaultFilter;
                try{
                    page_defaultFilter = this.user.default_value.default_filter[this.tab];
                }
                catch{
                    page_defaultFilter = undefined;
                }
                
                // get personal default
                if(this.personalSetting && page_defaultFilter !== undefined){
                    if(this.tab == 'Case' || this.tab == 'Opportunity'){
                        if(page_defaultFilter != null){
                            this.customFilters.forEach(e => {
                                if(e.id == page_defaultFilter[this.slcStgType.id]){
                                    e.default = true;
                                }else{
                                    e.default = false;
                                }
                            })
                        }
                    }else{
                        if(page_defaultFilter != null){
                            this.customFilters.forEach(e => {
                                if(e.id == page_defaultFilter){
                                    e.default = true;
                                }else{
                                    e.default = false;
                                }
                            })
                        }
                        else{
                            this.customFilters.forEach(e => e.default = false);
                        }
                    }
                }
                this.loading = false;
            })
        },
        setSystemDefault(newDefault){
            if(newDefault.default){
                let defaultFilter = this.customFilters.filter(e => e.default == true);
                defaultFilter.forEach(e => {
                    e.id != newDefault.id ? e.default = false : '';
                    CustomFilterDataService.update(e)
                    .catch(response => {
                        this.showErrorDialog(response);
                    })
                })
            }
            else{
                CustomFilterDataService.update(newDefault)
                .catch(response => {
                    this.showErrorDialog(response);
                })
            }
        },
        setDefault(item){
            this.customFilters.forEach(e => {
                if(e.default && e != item){
                    e.default = false;
                }
            })
            let defaultValue = this.userdata.usersetting.default_value;
            
            // make base json file
            if(defaultValue == null){
                defaultValue = {
                    'default_filter' : {
                        'Contact': null,
                        'Opportunity': null,
                        'Organization': null,
                        'Case': null,
                    },
                    "default_process": {
                        "case": null,
                        "opportunity": null
                    }
                }
            }
            else if(defaultValue.default_filter == undefined){
                defaultValue.default_filter = {
                    'Contact': null,
                    'Opportunity': null,
                    'Organization': null,
                    'Case': null,
                }
            }
            
            // set default data
            if(typeof(defaultValue.default_filter) == 'object'){
                if(this.tab == 'Case' || this.tab == 'Opportunity'){
                    if(defaultValue.default_filter[this.tab] == null){
                        defaultValue.default_filter[this.tab] = {};
                        for(let stage in this.stageTypes){
                            defaultValue.default_filter[this.tab][this.stageTypes[stage].id] = null;
                        }
                        defaultValue.default_filter[this.tab][this.slcStgType.id] = item.id;
                    }
                    else if(typeof(defaultValue.default_filter[this.tab]) == 'object'){
                        if(defaultValue.default_filter[this.tab][this.slcStgType.id] == item.id){
                            defaultValue.default_filter[this.tab][this.slcStgType.id] = null;
                        }
                        else{
                            defaultValue.default_filter[this.tab][this.slcStgType.id] = item.id;
                        }
                    }
                }
                else{
                    if(defaultValue.default_filter[this.tab] == item.id){
                        
                        defaultValue.default_filter[this.tab] = null;
                    }else{
                        defaultValue.default_filter[this.tab] = item.id;
                    }
                }
            }
            if(this.userdata.usersetting.default_value == null){
                this.userdata.usersetting.default_value = defaultValue;
            }
            CustomFilterDataService.userDefault(this.userdata)
            .then(() => {
                this.user.default_value = defaultValue;
                window.localStorage.setItem('user', JSON.stringify(this.user));
            });
        },
        getCustomField(){
            if(this.tab == "Contact" || this.tab == "Organization"){
                this.CFList = this.allCustomField.find(e => e.class_name == this.tab).definition;
            }else{
                let def = this.allCustomField.find(e => e.class_name == this.tab).definition;
                if(def != null){
                    try{
                        this.CFList = def.find(i => i.stage_type_id == this.slcStgType.id).data;
                    }
                    catch{
                        this.CFList = null;
                    }
                }
            }
        },
        // dialog
        showAddFilterDialog(){
            this.getCustomField();
            this.addFilterDialog = true;
        },
        onEmitAddFilter(val, data, contact_list, org_list, product_list, case_list){
            if(val){
                if(contact_list != {}){
                    data.contactList = {};
                    for(let c in contact_list){
                        data.contactList[c] = contact_list[c];
                    }
                }
                if(org_list != {}){
                    data.orgList = {};
                    for(let o in org_list){
                        data.orgList[o] = org_list[o];
                    }
                }
                if(product_list != {}){
                    data.productList = {};
                    for(let p in product_list){
                        data.productList[p] = product_list[p];
                    }
                }
                if(case_list != {}){
                    data.caseList = {};
                    for(let pc in case_list){
                        data.caseList[pc] = case_list[pc];
                    }
                }
                if(this.isSystem){
                    if(data.public){
                        this.customFilters.unshift(data);
                    }
                    this.allCustomFilter.unshift(data);
                }else{
                    this.customFilters.unshift(data);
                    this.allCustomFilter.unshift(data);
                }
                this.showSuccessDialog();
            }
            this.addFilterDialog = false;
        },
        async showEditFilterDialog(item){
            await this.getCustomField();
            this.editMode = 'EDIT';
            this.editData = item;
            this.editFilterDialog = true;
        },
        async showCopyFilterDialog(item){
            await this.getCustomField();
            this.editMode = 'COPY';
            this.editData = JSON.parse(JSON.stringify(item));
            this.editData.name = this.editData.name + '-Copy';
            this.editFilterDialog = true;
        },
        // onEmitEditFilter(bool, data){
        //     if(bool){
        //         this.showSuccessDialog();
        //     }
        //     else{
        //         if(data){
        //             for(let prop in this.editData){
        //                 this.editData[prop] = data[prop]
        //             }
        //         }
        //     }
        //     this.editFilterDialog = false;
        // },
        onEmitEditFilter(confirmEdit, orignData, editedData){
            
            if(confirmEdit){
                if(editedData){
                    // this.$emit('emitFilter',editedData, 'applyFilter');
                    this.$emit('emitFilter',editedData);
                    this.showSuccessDialog();
                }
            }
            else if(!confirmEdit){
                if(orignData != undefined && editedData != undefined){
                    for(let prop in this.editData){
                        let p = prop.toString();
                        this.$set(this.editData, p, orignData[prop]) 
                    }
                }
            }
            this.editFilterDialog = false;
        },
        onEmitCopyFilter(val, data, contact_list, org_list, product_list, case_list){
            if(val){
                if(contact_list != {}){
                    data.contactList = {};
                    for(let c in contact_list){
                        data.contactList[c] = contact_list[c];
                    }
                }
                if(org_list != {}){
                    data.orgList = {};
                    for(let o in org_list){
                        data.orgList[o] = org_list[o];
                    }
                }
                if(product_list != {}){
                    data.productList = {};
                    for(let p in product_list){
                        data.productList[p] = product_list[p];
                    }
                }
                if(case_list != {}){
                    data.caseList = {};
                    for(let pc in case_list){
                        data.caseList[pc] = case_list[pc];
                    }
                }

                this.customFilters.unshift(data);
                this.allCustomFilter.unshift(data);
                this.showSuccessDialog();
            }
            this.editFilterDialog = false;
        },
        deleteTable(item){
            this.showMessageDialog(i18n.t('FILTERSETTING.MESSAGE'));
            this.deleteData = item;
            this.mode = 'delete';
        },
        showMessageDialog(message) {
            this.messageDialog = true;
            this.message = message;
        },
        async onEmitMessageDialog(val) {
            this.messageDialog = false;
            if(val){
                if(this.mode == 'delete'){
                    CustomFilterDataService.delete(this.deleteData.id)
                    .then(() => {
                        this.showSuccessDialog();
                        this.customFilters = this.customFilters.filter(e => e.id != this.deleteData.id);
                        this.allCustomFilter = this.allCustomFilter.filter(e => e.id != this.deleteData.id);
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    })
                }
            }
        },
        showSuccessDialog() {
            this.successDialog = true;
        },
        onEmitSuccessDialog() {
            this.successDialog = false;
        },
        showErrorDialog(message) {  
            this.errorDialog = true;      
            this.errorMessage = message;
        },
        onEmitErrorDialog() {
            this.errorDialog = false;
        },
        
    },
});
</script>