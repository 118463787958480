<template lang="pug">
    #ManageGroups    
        loadingOverlay(v-if='loading' message='Loading . . .' :zIndex='999')
        v-row.setting-title.align-center.px-4.py-3(no-gutters)
            v-col(cols='12' md='8')
                h3.d-flex.align-center.mb-0
                    .cube-icon.small.bg-primary.mr-3
                        v-icon(size='17' color='white') icon-group
                    span {{$t('SETTING.MANAGEGROUPS')}}
        .px-4.py-3
            v-row.align-center.justify-space-between.mb-2(no-gutters)
                v-col(cols='4')
                    v-text-field.pa-0(v-model='searchGroup' flat dense solo height='40' min-height='40' prepend-inner-icon='icon-search')
                v-col.pl-4(cols='3').d-flex.justify-end
                    v-btn(@click='showAddGroupDialog' height='40' color='green' depressed dark)
                        v-icon.mr-1(size='14') icon-add
                        span {{$t('MANAGEGROUPS.ADD_GROUP')}}
            v-data-table.dashboard-table(
                multi-sort
                :headers='manageGroupHeaders' 
                :items='manageGroupData' 
                :items-per-page='itemPerPage' 
                :page.sync='page' 
                :item-class="rowClass"
                fixed-header 
                @page-count='pageCount = $event' 
                :search='searchGroup' 
                height='715' 
                :header-props="{sortIcon: 'mdi mdi-arrow-up'}"  
                hide-default-footer='hide-default-footer' 
                dense
            )
                template(v-slot:item.name='{ item }')
                    .ellipsis.pl-3
                        span(:title='item.name') {{ item.name }}    
                template(v-slot:item.permission_type_name='{ item }')
                    .ellipsis
                        span(:title='item.permission_type_name') {{ item.permission_type_name }}
                template(v-slot:item.active='{ item }')
                    v-btn(icon @click='showEditGroupDialog(item)')
                        v-icon(size='18') icon-edit
                    v-btn(icon @click='onDelete(item)')
                        v-icon(size='18') icon-remove
                v-pagination.py-1.mb-3(v-model='page' :length='pageCount' circle :total-visible="10")
        v-dialog(v-model='manageGroupDialog' max-width='500' content-class='setting-dialog' v-if='manageGroupDialog')
            v-card.mangeUserCard
                v-card-title.d-flex.justify-center.mb-4 {{ addOrEdit }} {{$t('MANAGEGROUPS.GROUP')}}
                v-card-text
                    v-form(v-model='addOrEditGroup.valid' ref='form')
                        v-row(no-gutters)    
                            v-col.mt-5(cols='12')
                                v-text-field.v-text-field__slot.height-58(v-model='addOrEditGroup.name' :label=`$t('MANAGEGROUPS.NAME')`, solo prepend-icon="mdi-rename-box" :rules="formRule.fieldRules")
                        v-row(no-gutters)  
                            v-col.pr-2(cols='12')                           
                                v-select.v-text-field__slot.height-58(v-model='addOrEditGroup.permission_type' :items="permissionTypeList" item-value="key" item-text="name", :label=`$t('MANAGEGROUPS.TYPE')`, solo prepend-icon='mdi-book-open-page-variant' ,:rules="formRule.fieldRules")
                        v-row(no-gutters)  
                            v-col.pr-2(cols='12')                           
                                v-select.v-text-field__slot.height-58(v-model='addOrEditGroup.user_set' multiple :items="ownerList" return-object item-value="id" item-text="fullName", :label=`$t('MANAGEGROUPS.MEMBER')`, solo prepend-icon='mdi-account-multiple' ,:rules="formRule.userfieldRules")
                    v-card-actions.mt-8.px-0.py-0
                        v-row(no-gutters)
                            v-col.pr-2(cols='6')
                                v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                            v-col.pl-2(cols='6')
                                v-btn(:ripple='false' @click='onAddOrEditGroup(addOrEditGroup)' width='100%' height='40' color='success' depressed dark) {{ addOrEditButton }}
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
                message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
                success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
                error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
    import Vue from 'vue';
    import messageDialog from '@/components/Dialog/messageDialog';    
    import PersonalSettingService from "@/services/PersonalSettingService";
    import OrganizationDataService from "@/services/OrganizationDataService";
    import PermissionService from "@/services/PermissionService";
    import successDialog from "@/components/Dialog/successDialog";
    import errorDialog from "@/components/Dialog/errorDialog";
    import loadingOverlay from "@/components/Common/loadingOverlay";    
    import permissionTypeList from "@/array/the_permissionTypeList";
    import i18n from '/common/plugins/vue-i18n.js'

    export default Vue.extend({
        props: {  
            
        },
        components: {  
            loadingOverlay,            
            messageDialog,
            successDialog,
            errorDialog,
        },        
        data() {
            return {
                addOrEdit: i18n.t('GENERAL.ADD'),
                addOrEditButton: i18n.t('GENERAL.ADD'),
                loading: false,    
                deleteGroupID: null,
                userId:null,
                manageGroupData: [],
                page: 1,
                pageCount: 1,
                itemPerPage: 100,
                manageGroupHeaders: [
                    {  text: i18n.t('MANAGEGROUPS.NAME'), value: 'name', align: 'left',width:'45%', class: "blue lighten-5"},
                    {  text: i18n.t('MANAGEGROUPS.TYPE'), value: 'permission_type_name', align: 'center',width:'40%', class: "blue lighten-5"},
                    {  text: i18n.t('MANAGEGROUPS.ACTIVE'), value: 'active', align: 'center',width:'15%', class: "blue lighten-5", sortable: false},
                ],
                searchGroup: "",
                manageGroupDialog: false,
                addOrEditGroup : this.initializeAddOrEditGroup(),
                errorMessage: "",
                message: "",
                messageDialog:false,
                errorDialog:false,
                successDialog:false,
                valid:false,            
                permissionTypeList: permissionTypeList,
                ownerList: [],
                formRule: {
                    fieldRules: [(v) => !!v || i18n.t('RULE.RULE_R')],
                    userfieldRules: [(v) =>  v.length>0 || i18n.t('RULE.RULE_R')],
                },
            }
        },
        async created()
        {
            await PersonalSettingService.getCurrentUserDetail()
            .then(response => 
            {  
                this.userId = response.data[0].id;                
            });
            await OrganizationDataService.getowner()
            .then((respose)=>{
                this.ownerList = respose.data;
                this.ownerList.forEach(item => {
                    item.fullName = item.last_name + ' ' + item.first_name
                });
            });            
            await PermissionService.getGroups()
            .then(response => 
            {   
                if (response.data.length > 0) {
                    this.manageGroupData = response.data;
                }
                this.getPermissionTypeDisplay();
            }).catch((response) => {
                this.showErrorDialog(response);
            });
        },
        methods:{
            getPermissionTypeDisplay() {
                this.manageGroupData = this.manageGroupData.map(
                    g => ({ ...g, 
                            permission_type_name: this.getItemNameFromList(this.permissionTypeList, g.permission_type) })
                );
            },
            validateForm(){
                this.valid = this.$refs.form.validate();
                if(this.valid)                    
                    return true;
                else
                    return false;
            },
            getNoAccessPrivilegeMessage() {
                return i18n.t('MANAGEGROUPS.ERROR');
            },
            getDuplicateNameMessage() {
                return i18n.t('MANAGEGROUPS.ERROR_2');
            },
            showSuccessDialog() {
                this.successDialog = true;
            },
            onEmitSuccessDialog() {
                this.successDialog = false;
             },
            showErrorDialog(message) {  
                this.errorDialog = true;      
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            showMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
            onEmitMessageDialog(val) {
                this.messageDialog = false;
                if(this.hasPrivilege())
                {
                    this.showErrorDialog(this.getNoAccessPrivilegeMessage());
                    return;
                }
                if(val){
                    PermissionService.deleteGroup(this.deleteGroupID)
                    .then(()=> {
                        let deleteItemIndex = this.manageGroupData.findIndex(g => g.id == this.deleteGroupID);
                        this.manageGroupData.splice(deleteItemIndex, 1);
                        this.deleteGroupID = null;
                        this.showSuccessDialog();
                    }).catch((response) => {
                        this.showErrorDialog(response.data);
                    });
                }
            }, 
            hasPrivilege() {
                return this.isAdmin;
            },
            initializeAddOrEditGroup() {
                return {
                    id: "",
                    name: "",
                    types: "",
                    all_permission_types: [],
                    user_set: [],
                };
            },
            showAddGroupDialog() {
                if(this.hasPrivilege())
                {
                    this.showErrorDialog(this.getNoAccessPrivilegeMessage());
                    return;
                }
                this.manageGroupDialog = true;
                this.addOrEdit = i18n.t('GENERAL.ADD');
                this.addOrEditButton = i18n.t('GENERAL.ADD');
                this.addOrEditGroup = this.initializeAddOrEditGroup();
            },
            showEditGroupDialog(data) {              
                if(this.hasPrivilege())
                {
                    this.showErrorDialog(this.getNoAccessPrivilegeMessage());
                    return;
                }
                this.manageGroupDialog = true;
                this.addOrEdit = i18n.t('GENERAL.EDIT');
                this.addOrEditButton = i18n.t('GENERAL.SAVE');
                this.addOrEditGroup = JSON.parse(JSON.stringify(data));
            },
            async editGroup(data) {
                this.loading = true;
                await PermissionService.editGroup(data)
                    .then((response)=>{                  
                        if(response.data.id)         
                        {
                            let editGroupIndex = this.manageGroupData.findIndex(g => g.id == this.addOrEditGroup.id);
                            this.manageGroupData[editGroupIndex] = JSON.parse(JSON.stringify(this.addOrEditGroup));
                            this.getPermissionTypeDisplay();
                            this.showSuccessDialog();
                            this.loading = false;
                        }
                    }).catch((response) => {
                        this.showErrorDialog(response);
                        this.loading = false;
                    });
            },
            async addGroup(data) {
                this.loading = true;
                await PermissionService.addGroup(data)
                    .then((response)=>{                  
                        if(response.data.id)
                        {
                            let newGroup = response.data;
                            newGroup.all_permission_types = this.addOrEditGroup.all_permission_types;
                            newGroup.permission_type = data.permission_type;
                            this.manageGroupData.unshift(newGroup);
                            this.getPermissionTypeDisplay();
                            this.showSuccessDialog();
                            this.loading = false;
                        }
                    })
                    .catch((exception) => {
                        this.showErrorDialog(exception);
                        this.loading = false;
                    });
            },
            async onAddOrEditGroup(data) {           
                if(this.isAdmin === false)
                {
                    this.showErrorDialog(this.getNoAccessPrivilegeMessage());
                    return;
                }
                if(this.validateForm()){
                    if (data.id) {      // Edit
                        if(this.manageGroupData.find(el => el.name == data.name && el.id != data.id))
                        {
                            this.showErrorDialog(this.getDuplicateNameMessage());
                            return;
                        }
                        await this.editGroup(data);
                    } else {            // Add
                        if(this.manageGroupData.find(el => el.name == data.name))
                        {
                            this.showErrorDialog(this.getDuplicateNameMessage());
                            return;
                        }
                        await this.addGroup(data);
                    }
                    this.manageGroupDialog = false;
                    this.addOrEditGroup = this.initializeAddOrEditGroup();
                }
            },
            onCancel(){
                this.manageGroupDialog = false
                this.addOrEditGroup = this.initializeAddOrEditGroup();
            },
            onDelete(item) {
                if(this.hasPrivilege())
                {
                    this.showErrorDialog(this.getNoAccessPrivilegeMessage());
                    return;
                }
                this.showMessageDialog(i18n.t('MANAGEGROUPS.MESSAGE'));
                this.deleteGroupID = item.id;
            },
            rowClass() {        
                const rowClass = 'dataTableColor'
                return rowClass;
            },
        }
    });
</script>