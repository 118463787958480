<template lang="pug">
#Setting-EmailTemplate
    v-row.setting-title.align-center.px-4.py-3(no-gutters)
        v-col(cols='6')
            h3.d-flex.align-center.mb-0
                .cube-icon.small.bg-primary.mr-3
                    v-icon(size='19' color='white') icon-mail
                span {{$t('SETTING.EMAILTEMPLATE')}}
        v-col.text-md-right(cols='6')
            span.blue-grey--text.lighten-2--text.text-body-2 {{ manageTemplateData.length }} {{$t('EMAILTEMPLATE.TEMPLATE')}}
    .px-4.py-3
        v-row.align-center.justify-space-between.mb-2(no-gutters)
            v-col(cols='4')
                v-text-field.pa-0(v-model='searchTemplate' flat dense solo height='40' min-height='40' prepend-inner-icon='icon-search')
            v-col.pl-4(cols='3').d-flex.justify-end
                v-btn(@click='showAddTemplateDialog' height='40' color='green' depressed dark)
                    v-icon.mr-1(size='14') icon-add
                    span {{$t('EMAILTEMPLATE.ADD_TEMPLATE')}}
        v-data-table.dashboard-table(:headers='manageTemplateHeaders' :header-props="{sortIcon: 'mdi mdi-arrow-up'}", :items='manageTemplateData' :items-per-page='itemPerPage' :page.sync='page' @page-count='pageCount = $event' :search='searchTemplate' multi-sort height='640px' hide-default-footer='hide-default-footer' dense)
            template(v-slot:item.template_name='{ item }')
                .ellipsis.pl-3
                    span(:title='item.template_name') {{ item.template_name }}    
            template(v-slot:item.created_on="{ item }")
                .ellipsis
                    span(:title='new Date(item.created_on).toLocaleString()') {{ new Date(item.created_on).toLocaleString() }}        
            template(v-slot:item.updated_on="{ item }")
                .ellipsis
                    span(:title='new Date(item.updated_on).toLocaleString()') {{ new Date(item.updated_on).toLocaleString() }}    
            template(v-slot:item.active='{ item, index }')
                v-btn(icon @click='showEditTemplateDialog(item, index)')
                    v-icon(size='18') icon-edit
                v-btn(icon @click='onDelete(index)')
                    v-icon(size='18') icon-remove
        v-pagination.py-1.mb-3(v-model='page' :length='pageCount' circle :total-visible="10")
    v-dialog(v-model='manageTemplateDialog' max-width='900' content-class='setting-dialog' v-if='manageTemplateDialog')
        v-card
            v-card-title.d-flex.justify-center.mb-4(v-if='addOrEditTemplate=="EDIT"') {{$t('EMAILTEMPLATE.EDIT_EMAIL_TEMPLATE')}}
            v-card-title.d-flex.justify-center.mb-4(v-if='addOrEditTemplate=="ADD"') {{$t('EMAILTEMPLATE.ADD_EMAIL_TEMPLATE')}}
            v-card-text
                v-form(v-model='template.valid' ref='form')
                    v-row(no-gutters)
                        v-col.mb-2(cols='12')
                            v-text-field(v-model='template.template_name' :label=`$t('EMAILTEMPLATE.NAME')` solo :rules="formRule.nameRules")
                        v-col.mb-2(cols='12')
                            v-text-field(v-model='template.subject' :label=`$t('EMAILTEMPLATE.SUBJECT')` solo :rules="formRule.subjectRules")
                        v-col(cols='12')
                            vue-editor(v-model='template.template' :editor-toolbar='customToolbar' @selection-change='onSelectionChange' @text-change='onTextChange')
                            .editor-custom-toolbar.text-right
                                v-menu.email_field(top :close-on-click='true' :close-on-content-click='false' )
                                    template(v-slot:activator='{ on, attrs }')
                                        v-btn.px-3(v-bind='attrs' v-on='on' text :ripple='false')
                                            v-icon.mr-1(size='15') icon-add
                                            span Fields
                                    v-card.fields
                                        v-tabs(v-model='emailTemplateTabs' hide-slider)
                                            //- v-tab.px-0(href="#tab-time" :ripple='false')
                                                v-icon(size='19') icon-time
                                            v-tab.px-0(href="#tab-dollar" :ripple='false')
                                                v-icon(size='19') icon-dollar
                                            v-tab.px-0(href="#tab-user" :ripple='false')
                                                v-icon(size='19') icon-user-1
                                            v-tab.px-0(href="#tab-case" :ripple='false')
                                                v-icon(size='19') mdi-wrench-outline
                                            //- v-tab.px-0(href="#tab-building" :ripple='false')
                                                v-icon(size='19') icon-building
                                            //- v-tab.px-0(href="#tab-dots" :ripple='false')
                                                v-icon(size='19') icon-dots
                                        v-tabs-items(v-model='emailTemplateTabs')
                                            //- v-tab-item(value='tab-time' transition='none')
                                            v-tab-item(value='tab-dollar' transition='none')
                                                v-list.d-flex.flex-column(dense)
                                                    v-btn(text @click="addEmailTemplateFields('opportunity.name')") Title
                                                    v-btn(text @click="addEmailTemplateFields('opportunity.owner')") Owner
                                                    v-btn(text @click="addEmailTemplateFields('opportunity.closed_date')") Closed Date
                                                    v-btn(text @click="addEmailTemplateFields('opportunity.closed_reason')") Closed Reason
                                                    v-btn(text @click="addEmailTemplateFields('opportunity.amount')") Amount
                                                    v-btn(text @click="addEmailTemplateFields('opportunity.primary_contact')") Primary Contact
                                                    v-btn(text @click="addEmailTemplateFields('opportunity.organization')") Organization
                                                    v-btn(text @click="addEmailTemplateFields('opportunity.stage_type')") Pipeline
                                                    v-btn(text @click="addEmailTemplateFields('opportunity.current_stage')") Current Stage
                                                    v-btn(text @click="addEmailTemplateFields('opportunity.customer_budget')") Customer Budget
                                            v-tab-item(value='tab-user' transition='none')
                                                v-list.d-flex.flex-column(dense)
                                                    v-btn(text @click="addEmailTemplateFields('contact.first_name')") First Name
                                                    v-btn(text @click="addEmailTemplateFields('contact.last_name')") Last Name
                                                    v-btn(text @click="addEmailTemplateFields('contact.owner')") Owner
                                                    v-btn(text @click="addEmailTemplateFields('contact.level')") Level
                                                    v-btn(text @click="addEmailTemplateFields('contact.department')") Department
                                                    v-btn(text @click="addEmailTemplateFields('contact.organization')") Organization
                                                    v-btn(text @click="addEmailTemplateFields('contact.email')") Email
                                                    v-btn(text @click="addEmailTemplateFields('contact.mobile_phone')") Mobile Phone
                                                    v-btn(text @click="addEmailTemplateFields('contact.office_phone')") Office Phone
                                                    v-btn(text @click="addEmailTemplateFields('contact.birthdate')") Birthday
                                                    v-btn(text @click="addEmailTemplateFields('contact.title')") Title
                                            v-tab-item(value='tab-case' transition='none')
                                                v-list.d-flex.flex-column(dense)
                                                    v-btn(text @click="addEmailTemplateFields('case.subject')") Subject
                                                    v-btn(text @click="addEmailTemplateFields('case.owner')") Owner
                                                    v-btn(text @click="addEmailTemplateFields('case.primary_contact')") Primary Contact
                                                    v-btn(text @click="addEmailTemplateFields('case.organization')") Organization
                                                    v-btn(text @click="addEmailTemplateFields('case.case_number')") Case Number
                                                    v-btn(text @click="addEmailTemplateFields('case.closed_date')") Closed Date
                                                    v-btn(text @click="addEmailTemplateFields('case.closed_reason')") Closed Reason
                                                    v-btn(text @click="addEmailTemplateFields('case.incoming_fullname')") Incoming Fullname
                                                    v-btn(text @click="addEmailTemplateFields('case.incoming_phone')") Incoming Phone
                                                    v-btn(text @click="addEmailTemplateFields('case.incoming_email')") Incoming Email
                                                    v-btn(text @click="addEmailTemplateFields('case.source')") Source
                                                    v-btn(text @click="addEmailTemplateFields('case.group')") Group
                                                    v-btn(text @click="addEmailTemplateFields('case.type')") Type
                                                    v-btn(text @click="addEmailTemplateFields('case.status')") Status
                                                    v-btn(text @click="addEmailTemplateFields('case.priority')") Priority
                                                    v-btn(text @click="addEmailTemplateFields('case.stage_type')") Process
                                                    v-btn(text @click="addEmailTemplateFields('case.current_stage')") Current Stage
                                            //- v-tab-item(value='tab-building' transition='none')
                v-card-actions.mt-8.px-0.py-0
                    v-row(no-gutters)
                        v-col.pr-2(cols='6')
                            v-btn(:ripple='false' @click='manageTemplateDialog = false' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                        v-col.pl-2(cols='6')
                            v-btn(v-if='addOrEditTemplate=="EDIT"' :ripple='false' @click='onEmailTemplateEdit()' width='100%' height='40' color='success' depressed dark) {{$t('GENERAL.CONFIRM')}}                                        
                            v-btn(v-if='addOrEditTemplate=="ADD"' :ripple='false' @click='onEmailTemplateAdd()' width='100%' height='40' color='success' depressed dark) {{$t('GENERAL.ADD')}}
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
        success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
        message-dialog(@emitMessageDialog='onEmitDeleteMessageDialog' :message='message')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
    import Vue from 'vue';
    import { VueEditor } from "vue2-editor";
    import EmailTemplateDataService from "../../services/EmailTemplateDataService";
    import errorDialog from '@/components/Dialog/errorDialog';
    import successDialog from '@/components/Dialog/successDialog';
    import messageDialog from '@/components/Dialog/messageDialog';
    import loadingOverlay from "@/components/Common/loadingOverlay";
    import ImageResize from "quill-image-resize-vue";
    import i18n from '/common/plugins/vue-i18n.js'

    export default Vue.extend({
        components: {
            VueEditor,
            loadingOverlay,
            errorDialog,
            messageDialog,
            successDialog
        },
        props: {},
        data() {
            return {
                page: 1,
                pageCount: 1,
                itemPerPage: 12,
                messageDialog: false,
                successDialog: false,
                errorDialog: false,
                message: '',
                errorMessage: '',
                searchTemplate: '',
                manageTemplateDialog: false,
                manageTemplateHeaders: [
                    {  text: i18n.t('EMAILTEMPLATE.NAME'), value: 'template_name', align: 'center', },
                    {  text: i18n.t('EMAILTEMPLATE.ADD_DATE'), value: 'created_on', align: 'center', },
                    {  text: i18n.t('EMAILTEMPLATE.END_TIME'), value: 'updated_on', align: 'center', },
                    {  text: i18n.t('EMAILTEMPLATE.ACTIVE'), value: 'active', align: 'center', width: '200px',sortable: false },
                ],
                manageTemplateData: [],
                addOrEditTemplate: '',
                deleteTemplateIndex: 0,
                editTemplateIndex: 0,
                template: {
                    valid: true,
                    template_name: '',
                    template: '',
                    templateIndex: '',
                },
                emailTemplateTabs: '',
                customToolbar: [
                    [{ 'header': [false, 1, 2, 3, ] }],
                    ["bold", "italic", "underline"],
                    [{'align': ''}, {'align': 'center'}, {'align': 'right'}, ],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ 'color': [] }, { 'background': [] }],
                    ['link']
                ],
                customModulesForEditor: [
                    { alias: "imageResize", module: ImageResize }
                ],
                editorSettings: {
                    modules: {
                        imageResize: {}
                    }
                },
                formRule: {
                    nameRules: [
						(v) => !!v || i18n.t('RULE.RULE_R_TEMPLATE_NAME'),
						(v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200')
					], 
                    subjectRules: [
						(v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200')
					],                   
                },
            }
        },
        created(){
            EmailTemplateDataService.getAll()
            .then(response => {
                this.manageTemplateData = (response.data);
            });
        },        
        methods: {
            validateForm(){
                if(this.$refs.form.validate()){                    
                    return true;
                }
                else{
                    return false;
                }
            },
            onDelete(dataIndex) {
                this.deleteTemplateIndex = dataIndex;
                this.showDeleteMessageDialog(i18n.t('EMAILTEMPLATE.MESSAGE'));
            },
            showDeleteMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
            showSuccessDialog() {
                this.successDialog = true;
            },
            onEmitDeleteMessageDialog(val) {
                this.messageDialog = false;
                if (val) { 
                    let id = this.manageTemplateData[this.deleteTemplateIndex].id;
                    EmailTemplateDataService.delete(id)
                    .then(() => {
                        this.manageTemplateData.splice(this.deleteTemplateIndex, 1);
                        this.showSuccessDialog();
                    })
                    .catch(response => {
                        this.errorDialog = true;
                        this.errorMessage = response;
                    });
                }
            },
            onEmitSuccessDialog() {
                this.successDialog = false;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            onSelectionChange(range) {
                this.template.templateIndex = range.index;
            },
            onTextChange(delta) {
                if (Object.keys(delta.ops[0])[0] == 'retain') {
                    if (Object.keys(delta.ops[1])[0] == 'insert') {
                        if (delta.ops[1].insert.length <= 2) {
                            this.template.templateIndex = delta.ops[0].retain + 1;
                        }
                    } else if (Object.keys(delta.ops[1])[0] == 'delete') {
                        this.template.templateIndex = delta.ops[0].retain;
                    }
                }
            },
            initializeTemplateDialog() {
                this.addOrEditTemplate = '';
                this.manageTemplateDialog = false;
            },
            showAddTemplateDialog() {
                this.addOrEditTemplate = 'ADD';
                this.manageTemplateDialog = true;
                this.template = {
                    template_name: '',
                    template: '',
                    templateIndex: 0,
                };
            },
            onEmailTemplateAdd() {
                if (!this.template.template_name)
                {
                    this.errorDialog = true;
                    this.errorMessage = i18n.t('EMAILTEMPLATE.ERROR');
                    return false;
                }                
                // call data service to add to DB
                if (this.validateForm()) {
                let data = {
                    'template_name': this.template.template_name,
                    'subject': this.template.subject,
                    'template': this.template.template
                };                
                EmailTemplateDataService.create(data)
                .then(response => {
                    this.manageTemplateData.push(response.data);
                    this.showSuccessDialog();
                    this.initializeTemplateDialog();
                })
                .catch(response => {
                    this.errorDialog = true;
                    this.errorMessage = response;
                });
                }
            },
            showEditTemplateDialog(data, dataIndex){
                this.addOrEditTemplate = 'EDIT';
                this.manageTemplateDialog = true;
                this.editTemplateIndex = dataIndex;
                this.template = {
                    template_name: data.template_name,
                    subject: data.subject,
                    template: data.template,
                    templateIndex: 0,
                };
            },
            onEmailTemplateEdit() {
                // call data service to update DB
                if (this.validateForm()) {
                let data = {
                    'id': this.manageTemplateData[this.editTemplateIndex].id, 
                    'template_name': this.template.template_name,
                    'subject': this.template.subject,
                    'template': this.template.template
                };                
                EmailTemplateDataService.edit(data)
                .then(response => {
                    this.manageTemplateData[this.editTemplateIndex].template_name = data.template_name;
                    this.manageTemplateData[this.editTemplateIndex].template = data.template;
                    this.manageTemplateData[this.editTemplateIndex].subject = data.subject;
                    this.manageTemplateData[this.editTemplateIndex].updated_on = response.data.updated_on;
                    this.showSuccessDialog();
                    this.initializeTemplateDialog();
                })
                .catch(response => {
                    this.errorDialog = true;
                    this.errorMessage = response;
                });
                }
            },
            onEditorBlur(quill) {
                console.log("editor blur!", quill);
            },
            onEditorFocus(quill) {
                console.log("editor focus!", quill);
            },
            addEmailTemplateFields(val) {
                let template = this.template;
                // val is selected field, e.g. {{contact.owner}}
                let injectedStr = `{{${val}}}`;
                const pattern = /<([A-Z][A-Z0-9]*)\b[^>]*>(.*?)<\/\1>|<([A-Z][A-Z0-9]*).*?\/>/gi;
                let html = template.template;
                if (html == "") {
                    template.template = injectedStr;
                    this.template.templateIndex = injectedStr.length;
                    return true;
                }
                let htmlArr = html.match(pattern);
                let htmlTextSizeArr = htmlArr.map(t => t.replace(/<[^>]+>/g,"").length+1);	// get text length of within each html tag
                let position = template.templateIndex;						// position of cursor.  If 0, add to the end
                if (position == 0) {
                    let text = htmlArr[0];
                    let lastPos = text.indexOf(">") + 1;
                    let newText = [text.slice(0, lastPos), injectedStr, text.slice(lastPos)].join('');
                    htmlArr[0] = newText;
                    this.template.templateIndex = injectedStr.length;
                } else {
                    let i = 0;
                    while(position >= 0) {
                        position -= htmlTextSizeArr[i++];
                    }
                    let targetInArr = i-1;
                    if (htmlTextSizeArr[targetInArr] == 1) {	// only <br> in the line
                        htmlArr[targetInArr] = htmlArr[targetInArr].replace('<br>', injectedStr);
                    } else {
                        let htmlText = htmlArr[targetInArr];
                        let positionInArr = position + htmlTextSizeArr[targetInArr];
                        let j = 0;
                        let startCounting = true;
                        while(positionInArr > 0) {
                            let currentChar = htmlText.charAt(j);
                            switch(currentChar) {
                                case "<":
                                    startCounting = false;
                                    break;
                                case ">":
                                    startCounting = true;
                                    break;
                                default:
                                    if (startCounting)
                                        positionInArr--;
                                    break;
                            }
                            j++;
                        }
                        let newHtmlText = [htmlText.slice(0, j), injectedStr, htmlText.slice(j)].join('');
                        htmlArr[targetInArr] = newHtmlText;
                    }
                    this.template.templateIndex = this.template.templateIndex + injectedStr.length;
                }
                template.template = htmlArr.join('');
            },
        }
    });
</script>
<style lang="css">
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
</style>