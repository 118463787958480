<template lang="pug">
#Setting-EmailSync
    v-row.setting-title.align-center.px-4.py-3(no-gutters)
        v-col(cols='12' sm='9' xl='10')
            h3.d-flex.align-center.mb-0
                .cube-icon.small.bg-primary.mr-3
                    v-icon(size='19' color='white') icon-mail
                span {{$t('SETTING.EMAILSYNC')}}
        v-col.text-md-right(cols='12' sm='3' xl='2')
            v-btn(v-if='o365AccountData.length != 0 && h1_active[0]==true && this.canSync == true' @click='startSync' width='100%' height='40' color='green' depressed dark)
                v-icon.mr-1(size='20' v-if='!isSyncing') mdi-flip-h mdi-sync
                span {{ isSyncing ? $t('EMAILSYNC.SYNCING'):$t('EMAILSYNC.START_SYNC')}}
                v-progress-circular(size='18' v-if='isSyncing' indeterminate color='white')
            v-btn(v-if='o365AccountData.length == 0' @click='showAddEmail' width='100%' height='40' color='green' depressed dark)
                v-icon.mr-1(size='14') icon-add
                span {{$t('EMAILSYNC.ADD_ACCOUNT')}}
    .setting-inner(v-if='notEmailSetting')
        h4 {{$t('EMAILSYNC.TEXT')}}
        p {{$t('EMAILSYNC.TEXT_2')}}
        v-row.sheet-box.my-5(v-if='o365AccountData.length == 0')
            v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                    h5.ma-0.t-black {{$t('EMAILSYNC.NO_CONNECT')}}
        v-list-item(v-else v-for='item in o365AccountData' :key='item.id')
            v-list-item-content
                v-list-item-title.d-flex.justify-space-between.align-center
                    span.f-text-highlight.text-subtitle-2.mb-0 {{ item.o365_user_principal_name }}
                    span.div 
                        v-btn(icon='icon' @click='onDeleteO365Account(item.id)')
                            v-icon(size='18') icon-remove
        h4.pt-2(v-if='o365AccountData.length != 0 && this.canSync == true') {{$t('EMAILSYNC.H1')}}
            //- v-checkbox.ma-0.pa-0.pt-1(:input-value='h1_active[0]' :label=`$t('EMAILSYNC.H1_1')` @click='h1_active=[true,false]') 
            //- v-checkbox.ma-0.pa-0(:input-value='h1_active[1]' :label=`$t('EMAILSYNC.H1_2')` @click='h1_active=[false,true]') 
            v-checkbox.ma-0.pa-0.pt-1(:readonly='h1_active[0]', :input-value='h1_active[0]' :label=`$t('EMAILSYNC.H1_3')` @click='syncCheck(true)') 
            v-checkbox.ma-0.pa-0(:readonly='h1_active[1]', :input-value='h1_active[1]' :label=`$t('EMAILSYNC.H1_4')` @click='syncCheck(false)') 
        h4(v-if='o365AccountData.length != 0 && h1_active[0]==true && this.canSync == true') {{$t('EMAILSYNC.H2')}}
            v-checkbox.ma-0.pa-0.pt-1(:readonly='h2_active[0]',:input-value='h2_active[0]' :label=`$t('EMAILSYNC.H2_1')` @click='syncScopeCheck(0)') 
            v-checkbox.ma-0.pa-0(:readonly='h2_active[1]',:input-value='h2_active[1]' :label=`$t('EMAILSYNC.H2_2')` @click='syncScopeCheck(1)')
            v-checkbox.ma-0.pa-0(:readonly='h2_active[2]',:input-value='h2_active[2]' :label=`$t('EMAILSYNC.H2_3')` @click='syncScopeCheck(2)')
            v-checkbox.ma-0.pa-0(:readonly='h2_active[3]',:input-value='h2_active[3]' :label=`$t('EMAILSYNC.H2_4')` @click='syncScopeCheck(3)')
            //- v-row
            //-     v-col.pl-0(cols='3')
            //-         v-checkbox.ma-0.pa-0(:readonly='h2_active[4]',:input-value='h2_active[4]' :label=`$t('EMAILSYNC.H2_5')` @click='syncScopeCheck(4)') 
            //-     v-col.pl-0(cols='3')
            //-         v-text-field.no-border.height-48( v-model="custom_scope" single-line counter type="number",solo dense flat)
        //- h4 {{$t('EMAILSYNC.H3')}}
        //-     v-checkbox.ma-0.pa-0.pt-1(:input-value='h3_active[0]' :label=`$t('EMAILSYNC.H3_1')` @click='h3_active=[true,false]') 
        //-     v-checkbox.ma-0.pa-0(:input-value='h3_active[1]' :label=`$t('EMAILSYNC.H3_2')` @click='h3_active=[false,true]') 
        h4(v-if='o365AccountData.length != 0 && h1_active[0]==true && this.canSync == true') {{$t('EMAILSYNC.H4')}}
            h6 {{$t('EMAILSYNC.H5')}}
            h5 {{$t('EMAILSYNC.H6')}}
                v-combobox.v-text-field__slot.mt-1( v-model="h6_list" :items="h6_list" multiple chips  @input='emailCheck')
            h5 {{$t('EMAILSYNC.H7')}}
                v-combobox.v-text-field__slot.mt-1(v-model="h7_list" :items="h7_list" multiple chips  @input='domainsCheck')
            //- h5 {{$t('EMAILSYNC.H8')}}
            //-     v-combobox.v-text-field__slot.mt-1(v-model="h8_list" :items="h8_list" multiple chips  )
    //-     hr
    //-     p Tab switching and manually forwarding emails to CRM is a thing of the past. When unlocked, you can use your Sales Inbox to send email directly from CRM while automatically linking them to related deals and contacts.
    //-     p
    //-         v-icon.mr-2(color='primary' size='20') icon-check
    //-         span Sync CRM emails with any major email provider
    //-     p
    //-         v-icon.mr-2(color='primary' size='20') icon-check
    //-         span Track email opens and clicks
    //-     p
    //-         v-icon.mr-2(color='primary' size='20') icon-check
    //-         span Save time by making use of customizable templates
    //-     p
    //-         v-icon.mr-2(color='primary' size='20') icon-check
    //-         span Customize your signature for a more professional look
    //-     hr
    //-     br
    //-     h4 Smart email BCC address
    //-     p Add your Smart BCC address to the Cc or Bcc line when emailing customers or forward incoming emails to this address to automatically copy your emails to CRM.
    //-     v-chip(color='primary') abc@crm.io
    //-     br
    //-     br
    //-     h4 Default email sharing
    //-     v-radio-group(v-model='emailSharing')
    //-         v-radio.pt-4(value='1' label='Share all my linked email conversations with others in my company')
    //-         p.text-caption Email conversations will be visible to others only when they are linked to contacts and deals in CRM.
    //-         v-radio.pt-0(value='2' label='Keep all my email conversations private')
    //-         p.text-caption Email conversations can still be linked to contacts and deals in CRM, but they will only be visible to you.
    //-     br
    //-     p You can mark specific emails Shared or Private at any time to override this setting.
    //-     h4 Link emails to leads and deals
    //-     v-radio-group(v-model='emailLink')
    //-         v-radio.pt-4(value='1' label='Link emails manually')
    //-         v-radio.pt-0(value='2' label='Automatically link emails to leads or deals')
    //-         p.text-caption CRM will look for an open lead or deal related to the contact person the email was sent to or received from. When only one open lead or deal is found that matches the contact person, the email will be linked to it automatically.
    //- .setting-inner(v-else)
    //-     h4 Email sync
    //-     v-card.my-4(outlined flat)
    //-         v-row.align-center(no-gutters)
    //-             v-col(cols='9')
    //-                 v-list-item
    //-                     v-list-item-avatar
    //-                         v-img(src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcST1EmUdbH4RWGrWUTjmrYT295uwgZYbzTKcYiDkZEJiZ6OtWAm6c3AFLrI-raT3_8FLTI&usqp=CAU')
    //-                     v-list-item-content
    //-                         v-list-item-title abc@gmail.com
    //-                         v-list-item-subtitle
    //-                             v-icon(color='success') icon-check
    //-                             span Active
    //-             v-col.pr-4(cols='3')
    //-                 v-btn(@click='stopSyncing' width='100%' height='40' color='red' depressed dark)
    //-                     span Stop syncing
    //-     p Having trouble with your email sync? Learn more from the article Troubleshooting the Email Sync
    //-     h4 Labels
    //-     v-radio-group(v-model='emailSharing')
    //-         v-radio.pt-4(value='1' label='All emails will be synced with CRM')
    //-         v-radio.pt-0(value='2' label='Only emails with certain labels will be synced with CRM')
    //-     h4.mt-4.mb-2 Archive and Delete
    //-     v-switch(color='primary' label='Archive in my email client when archiving in CRM' value='true' hide-details)
    //-     v-switch(color='primary' label='Delete from my email client when deleting from CRM' value='true' hide-details)
    //-     p.text-caption.mt-2 Archiving emails in your email client will not archive emails in CRM.#[br]Deleting emails in your email client will always delete emails in CRM.
    //-     br
    //-     br
    //-     h4.mt-4.mb-2 Email tracking
    //-     v-switch(color='primary' label='Open tracking' value='true' hide-details)
    //-     p.text-caption.mt-1.mb-3 Track when email messages have been opened by recipients.
    //-     v-switch(color='primary' label='Link tracking' value='true' hide-details)
    //-     p.text-caption.mt-1.mb-3 Track when links in email messages have been clicked by recipients.
    //-     v-switch(color='primary' label='Notifications for tracking activities' value='true' hide-details)
    //-     p.text-caption.mt-1.mb-3 Get in-app notifications about open and link tracking activities.
    //-     br
    //-     br
    //-     h4 Email links
    //-     p When you click an email link in CRM, you can either open it in CRM or use the default email app in your computer.
    //-     v-radio-group(v-model='emailLink')
    //-         v-radio.pt-4(value='1' label='Open email links in Pipedrive (recommended)')
    //-         v-radio.pt-0(value='2' label='Use the default email app in my computer to open email links')
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
        message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
        success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='connectEmailAccountDialog' max-width='500' content-class='setting-dialog')
        v-card
            v-card-title.d-flex.justify-center.mb-4 {{$t('EMAILSYNC.DIALOG')}}
            v-card-text
                v-form(v-model='connectEmail.valid' ref='form')
                    v-row(no-gutters)
                        v-col.pr-2(cols='12')
                            // v-text-field(v-model='connectEmail.emailAddress' label='Email Address' solo)
                            p {{$t('EMAILSYNC.DIALOG_2')}}
                            //- Click add button will redirect you to O365 login page.  Once succeed, you will be redirected back to setting page
                v-card-actions.mt-8.px-0.py-0
                    v-row(no-gutters)
                        v-col.pr-2(cols='6')
                            v-btn(:ripple='false' @click='connectEmailAccountDialog = false' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                        v-col.pl-2(cols='6')
                            v-btn(:ripple='false' @click='onEmailAccountAdd' width='100%' height='40' color='success' depressed dark) {{$t('GENERAL.ADD')}}
    v-dialog(v-model='confirmEmailAccountDialog' max-width='500' content-class='setting-dialog')
        v-card
            v-card-title.d-flex.justify-center.mb-4 {{$t('EMAILSYNC.DIALOG_S')}}
            v-card-text
                v-form(v-model='connectEmail.valid' ref='form')
                    v-row(no-gutters)
                        v-col.pr-2(cols='12')
                            // v-text-field(v-model='connectEmail.emailAddress' label='Email Address' solo)
                            p {{$t('EMAILSYNC.DIALOG_S2')}}
                v-card-actions.mt-8.px-0.py-0
                    v-row(no-gutters)
                        v-col.pr-2(cols='12')
                            v-btn(:ripple='false' @click='closeConnectEmailAccount' width='100%' height='40' color='success' depressed dark) {{$t('GENERAL.CLOSE')}}
    v-dialog(v-model='connectEmailAccountSettingDialog' max-width='560' content-class='setting-dialog')
        v-card
            v-card-title.d-flex.justify-center.mb-4 Which emails should be synced?
            v-card-text
                p.text-caption
                    span You're about to connect
                    strong abc@gmail.com
                    span with CRM.
                v-form(v-model='connectEmail.valid' ref='form')
                    h4 Sync emails with:
                    v-radio-group(v-model='syncEmailWith')
                        v-radio.pt-4(value='1' label='All emails will be synced with CRM')
                        v-radio.pb-4(value='2' label='Only emails with certain labels will be synced with CRM')
                    h4.mt-2 Sync starting from:
                    v-radio-group(v-model='syncStartFrom')
                        v-radio.pt-4(value='1' label='Now')
                        v-radio.pt-0(value='2' label='1 month ago')
                        v-radio.pt-0(value='3' label='3 month ago')
                        v-radio.pb-4(value='4' label='6 month ago')
                    h4.mt-2 Sync past emails sent to and received from:
                    v-radio-group(v-model='syncReceivedFrom')
                        v-radio.pt-4(value='1' label='CRM contacts only')
                        v-radio.pb-4(value='2' label='All people')
                v-card-actions.mt-8.px-0.py-0
                    v-row(no-gutters)
                        v-col.pr-2(cols='6')
                            v-btn(:ripple='false' @click='connectEmailAccountSettingDialog = false' width='100%' height='40' color='cancel' depressed dark) Cancel
                        v-col.pl-2(cols='6')
                            v-btn(:ripple='false' @click='onEmailSync' width='100%' height='40' color='success' depressed dark) Start Syncing                            
</template>

<script>
    import Vue from 'vue';
    import O365Service from "../../services/O365Service";
    import PreferenceService from "../../services/PreferenceService";
    import errorDialog from '@/components/Dialog/errorDialog';
    import messageDialog from '@/components/Dialog/messageDialog';
    import successDialog from "@/components/Dialog/successDialog";
    import i18n from '/common/plugins/vue-i18n.js'  

    export default Vue.extend({
        components: {
            errorDialog,
            messageDialog,
            successDialog,
        },
        props: {},
        data() {
            return {
                custom_scope:1,
                h1_active:[false,false],
                h2_active:[false,false,false,false,false],
                h3_active:[false,false],
                h6_list:[],
                h7_list:[],
                h8_list:[],
                items: ['abc@gmail.com', 'ccc123@yahoo.com.tw'],
                fakevalues: ['wrong.io', 'fake.com', 'artsolutions.io'],
                successDialog: false,
                notEmailSetting: true,
                confirmEmailAccountDialog: this.$route.query.confirmO365Setup == 'Y',
                connectEmailAccountDialog: false,
                connectEmailAccountSettingDialog: false,
                connectEmail: {
                    emailAddress: '',
                },
                emailLink: 0,
                emailSharing: 0,
                syncEmailWith: 0,
                syncStartFrom: 0,
                syncReceivedFrom: 0,
                o365AccountData: [],
                messageDialog: false,
                message: '',
                syncData:null,
                isSyncing:false,
                canSync:false,
                errorDialog:false,
                errorMessage:'',
            }
        },
        async created(){
            const USER = 'user';
            let userJson = window.localStorage.getItem(USER);
            let user = JSON.parse(userJson);
            let userID = user.userid;
            await O365Service.getO365Accounts()
            .then(response => {
                this.o365AccountData = (response.data);
            });
            await PreferenceService.getEmailSync()
            .then((response)=>{
                try{
                    if(response.data.sync == true){
                        this.canSync = true;
                    }
                }
                catch(e){
                    console.log(e);
                }
            })
            await O365Service.getEmailSetting(userID)
            .then((response)=>{
                this.syncData = response.data;
                this.set_sync_setting();
            })
        },        
        methods: {
            startSync(){
                if(!this.isSyncing){
                    this.isSyncing = true;
                    O365Service.syncEmail()
                    .then((r)=>{
                        if(r.data == "inbox"){
                            this.errorDialog = true;
                            this.errorMessage = i18n.t('EMAILSYNC.TOO_MUCH_INBOX');
                        }
                        if(r.data == "sentbox"){
                            this.errorDialog = true;
                            this.errorMessage = i18n.t('EMAILSYNC.TOO_MUCH_SENTBOX');
                        }
                        this.isSyncing = false;
                    })
                }
            },
            syncCheck(val){
                if(val == this.syncData.enable_email_sync){
                    return;
                }
                if(val){
                    this.syncData.enable_email_sync =true;
                }else{
                    this.syncData.enable_email_sync =false;
                }
                this.updateSetting();
            },
            syncScopeCheck(val){
                if(this.h2_active[val] == true){
                    return;
                }
                switch(val){
                    case 0:
                        this.syncData.sync_scope = 1;
                        break;
                    case 1:
                        this.syncData.sync_scope = 30;
                        break;
                    case 2:
                        this.syncData.sync_scope = 90;
                        break;
                    case 3:
                        this.syncData.sync_scope = 180;
                        break;
                    case 4:
                        this.syncData.sync_scope = this.custom_scope;
                        break;
                    default:
                        break;
                }
                this.updateSetting();
            },
            set_sync_setting(){
                if(this.syncData.enable_email_sync){
                    this.h1_active = [true,false];
                }else{
                    this.h1_active = [false,true];
                }
                switch(this.syncData.sync_scope){
                    case 1:
                        this.h2_active = [true,false,false,false,false];
                        break;
                    case 30:
                        this.h2_active = [false,true,false,false,false];
                        break;
                    case 90:
                        this.h2_active = [false,false,true,false,false];
                        break;
                    case 180:
                        this.h2_active = [false,false,false,true,false];
                        break;
                    // case 4:
                    //     this.h2_active = [false,false,false,false,true];
                    //     break;
                    default:
                        break;
                }
               this.h6_list = this.syncData.ignore_email.ignored_addresses;
               this.h7_list = this.syncData.ignore_email.ignored_domains;
            },
            domainsCheck(e){
                this.syncData.ignore_email.ignored_domains = e;
                this.updateSetting();
            },
            emailCheck(e){
                let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if(!reg.test(e[e.length-1])){
                    e.pop();
                    if(e.length == 0){
                        this.syncData.ignore_email.ignored_addresses = e;
                        this.updateSetting();
                    }
                    return;
                }
                this.syncData.ignore_email.ignored_addresses = e;
                this.updateSetting();
            },
            updateSetting(){
                O365Service.editEmailSetting(this.syncData)
                .then((response)=>{
                    this.syncData = response.data;
                    this.set_sync_setting();
                })
            },
            onDeleteO365Account() {
                this.messageDialog = true;
                this.message = i18n.t('EMAILSYNC.MESSAGE');
            },
            onEmitMessageDialog(val) {
                this.messageDialog = false;
                if (val) {
                    this.confirmDeleteO365Account();
                }
            },
            onEmailSync() {
                this.connectEmailAccountSettingDialog = false;
            },
            stopSyncing() {
                this.showMessageDialog('Are you sure stop syncing?');
            },
            showAddEmail() {
                this.connectEmailAccountDialog = true;
            },
            showSuccessDialog() {
                this.successDialog = true;
            },
            onEmitSuccessDialog() {
                this.successDialog = false;            
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            confirmDeleteO365Account() {
                this.loading = true;
                let id = this.o365AccountData[0].id;
                O365Service.deleteO365Accounts(id)
                .then(() => {
                    this.loading = false;
                    this.showSuccessDialog();
                    var index = this.o365AccountData.map(x => { return x.Id; }).indexOf(id);
                    this.o365AccountData.splice(index, 1);
                })
                .catch(() => {
                    this.loading = false;
                    this.errorDialog = true;
                    this.errorMessage = i18n.t('EMAILSYNC.ERROR');
                });
            },
            onEmailAccountAdd() {
                O365Service.addO365Account()
                .then(response => {
                    this.confirmEmailAccountDialog = true;
                    var url = response.data;
                    window.location.href = url;
                });
            },
            closeConnectEmailAccount(){
                this.confirmEmailAccountDialog = false;
                window.location.search='';
            },
        }
    });
</script>

<style>
    .v-label.theme--light {
        color: rgba(0, 0, 0) !important;
    }
</style>